import React from 'react'

export default class Home extends React.Component {
  // 生命周期
  componentDidMount () {
    sessionStorage.removeItem('crumbsData');
  }
  render() {
    return (
      <div></div>
    )
  }
}