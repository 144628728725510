import React from 'react'
import { connect } from 'react-redux'
import SideMenu from '@/components/sideMenu'
import style from './style.module.less'
// import commonurl  from '@/axios/commonurl'
import { message } from 'antd';
import {
  loginOut
} from '@/api/system'
@connect(mapStateToProps)
class ControlBoard extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
    }
  }
  logoutOprate  = () => {
    loginOut({}).then(res => {
      if (res.data.code === '200' ) {
        message.success('操作成功！')
        window.location.href = '/login';
      } else {
        message.error(res.data.msg)
      }
    })
  }
  render() {
    // const reactEnv = process.env.REACT_APP_ENV
    const { staffDetail } = this.props

    return (
      <div>
        <div className={`com-flex justify-between ${style['pageHeader']}`}>
          <div>
            <img className={style['logo-img']} src={require('../../assets/images/logo.png')} alt="logo" />
            <span>汽配运营后台</span>
          </div>
          <div>
            <img height="44px" src={require('../../assets/images/pic-user.png')} alt="头像"/>
            <span className={style['userName']}>{staffDetail.userName}</span>
            <div onClick={()=>this.logoutOprate()} className={style['logout']} id="logout" title="退出系统">退出</div>
          </div>
        </div>
        <SideMenu></SideMenu>
        {/* {renderRoutes(this.props.route.routes)} */}
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    staffDetail: state.staffDetail
  }
}

export default ControlBoard
