/* 促销管理 -- 路由 */
import asyncComponent from '@/components/asyncComponent/asyncComponent';
const route = [
  {
    // 促销管理
    path: '/auth/promotion',
    component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/index')),
    routes: [
      {
        path: '/auth/promotion/promotionPage',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/promotionPage/promotionPage')),
        meta: {
          title: '积压件一口价列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path:'/auth/promotion/platformPromotion',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/platformPromotion/platformPromotion')),
        meta: {
          title: '平台活动列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/addPromotionPage',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/promotionPage/addPromotionPage')),
        meta: {
          title: '创建促销 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/brandGoodsPage',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/brandGoodsPage/brandGoodsPage')),
        meta: {
          title: '品牌商品管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/addGoodsPage',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/brandGoodsPage/addGoodsPage')),
        meta: {
          title: '品牌添加商品 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/brandListPage',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/brandGoodsPage/brandListPage')),
        meta: {
          title: '品牌商品列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/mimsList',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/mimsPage/list')),
        meta: {
          title: '汽配展列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/exhibitorsGoodsList',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/mimsPage/exhibitorsGoodsList')),
        meta: {
          title: '参展商品列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/mimsAddGoods',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/mimsPage/addGoods')),
        meta: {
          title: '汽配展-添加商品 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/exhibitorsList',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/mimsPage/exhibitorsList')),
        meta: {
          title: '汽配展-参展商户列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/couponCensus',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/coupon/couponVerify')),
        meta: {
          title: '用户优惠券核券 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/couponList',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/coupon/madeCouponList')),
        meta: {
          title: '优惠券列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/sendCouponList',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/coupon/sendCouponList')),
        meta: {
          title: '活动发券 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/madeCoupon',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/coupon/madeCoupon')),
        meta: {
          title: '制券 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/sendCoupon',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/coupon/sendCoupon')),
        meta: {
          title: '发券 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/promotionList',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/promotionPage/promotionList')),
        meta: {
          title: '商品促销列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/scrapPartsPage',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/scrapPartsPage/index')),
        meta: {
          title: '拆车商家管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/scrapPartsAddPage',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/scrapPartsPage/add')),
        meta: {
          title: '拆车添加商品 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/auctionPage',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/auction/index')),
        meta: {
          title: '拍卖管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/auctionProductPage',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/auction/product')),
        meta: {
          title: '拍卖品管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/promotion/activeSettingPage',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/activeSettingPage/activeSetting')),
        meta: {
          title: '活动页面设置 | 犇犇汽配联盟后台'
        }
      },
      {
        path:'/auth/promotion/backLogList',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/promotionPage/backLogList')),
        meta: {
          title: '积压件拍卖列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path:'/auth/promotion/goodsStatic',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/promotionPage/goodsStatic')),
        meta: {
          title: '活动商品统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path:'/auth/promotion/operationTool',
        component: asyncComponent(() => import(/* webpackChunkName: "promotion" */ '@/pages/promotion/promotionPage/operationTool')),
        meta: {
          title: '活动商品运营公爵 | 犇犇汽配联盟后台'
        }
      }
    ]
  }
];

export default route;
