import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { KeepAlive } from 'react-activation';
const renderRoutes = (routes, extraProps = {}, switchProps = {}) => 
routes ? (
    <Switch {...switchProps}>
        {routes.map((route, i) => (
        <Route
            key={route.key || i}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={props => (
              <KeepAlive id={props.location.pathname} name={props.location.pathname} when={[true, true]}>
                <route.component {...props} {...extraProps} route={route} />
              </KeepAlive>
            )}
        />
        ))}
    </Switch>
) : null;

 export default renderRoutes;