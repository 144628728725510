// reducer要根据action的type来返回不同的state 每个项目有且可以有多个action
// reducer： 可以理解为一个专门处理state的工厂 给他一个旧数据它会根据不同action.type返回新的数据
//           也就是：旧state + action = 新state 每个项目有且可以有多个reducer
import { SETSTAFFDETAIL } from './action-type'

// 登录员工信息
// reducer 改变state值  创建staffDetail reducer
export const staffDetail = (state = {}, action) => {
  switch (action.type) {
    case SETSTAFFDETAIL:
      return action.data
    default:
      return state
  }
}
