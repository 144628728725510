/* 联盟专区路由 */
import asyncComponent from '@/components/asyncComponent/asyncComponent'
const route = [
  {
    // 联盟
    path: '/auth/league',
    component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/index')),
    routes: [
      {
        path: '/auth/league/articleManage',
        component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/articleManage/articleManage')),
        meta: {
          title: '文章管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/league/forumPostManage',
        component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/forumPostManage/forumPostManage')),
        meta: {
          title: '帖子管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/league/leagueMemberCheck',
        component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/leagueMemberCheck/leagueMember')),
        meta: {
          title: '新联盟成员审核 | 犇犇汽配联盟后台'
        }
      }, 
      {
        path: '/auth/league/creatLeagueCheck',
        component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/creatLeagueCheck/creatLeagueCheck')),
        meta: {
          title: '申请新建联盟审核 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/league/leagueList',
        component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/creatLeagueCheck/leagueList')),
        meta: {
          title: '联盟列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path:'/auth/league/leagueENT',
        component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/child/leagueENT')),
        meta: {
          title: '联盟企业管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path:'/auth/league/saleDemand',
        component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/child/saleDemand')),
        meta: {
          title: '销售需求列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path:'/auth/league/purchaseDemand',
        component: asyncComponent(() => import(/* webpackChunkName: "league" */ '@/pages/league/child/purchaseDemand'))
      }
    ]
  }
]

export default route