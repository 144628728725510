import { createStore, combineReducers } from 'redux'
import { staffDetail } from './reducers'

// 当前数据的快照，初始化在reducer中插入
// const initialState = [{
//     text: 1,
// }]

// reducer 改变state值   // 创建todos reducer
// export function todos (state = initialState, action) {
//     switch (action.type) {
//         case 'x':
//             return 2
//         case 'ADD_TODO':
//             return state.text + action.text
//         default: // 默认返回initialState数据
//             return initialState
//     }
// }
// // 创建counter reducer
// export function counter(state = 0, action) {
//     switch (action.type) {
//       case 'INCREMENT':
//         return state + 1
//       case 'DECREMENT':
//         return state - 1
//       default:
//         return state
//     }
// }
// combineReducers 辅助函数的作用是，把一个由多个不同 reducer 函数作为 value 的 object，
// 合并成一个最终的 reducer 函数，然后就可以对这个 reducer 调用 createStore 方法。
const reducers = combineReducers({
    staffDetail
    // todos,
    // counter
})

// tore： store本质上是一个状态树，保存了所有对象的状态。任何UI组件都能直接的从store访问特定对象的状态。每个项目有且只能有一个store
export default createStore(reducers)
