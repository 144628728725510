/* 运营面板 -- 路由 */
import asyncComponent from '@/components/asyncComponent/asyncComponent';
const route = [
  {
    path: '/auth/operate',
    component: asyncComponent(() => import(/* webpackChunkName: "operate" */ '@/pages/operate')),
    routes: [
      {
        path: '/auth/operate/serviceOrder',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/serviceOrder')),
        meta: {
          title: '客服工单 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/memberPanel',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/memberPanel')),
        meta: {
          title: '会员管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/dataPanel',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/dataPanel')),
        meta: {
          title: '数据监测 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/newTradingPanel',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/newTradingPanel')),
        meta: {
          title: '交易面板 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/userActive',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/userActive')),
        meta: {
          title: '用户活跃 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/visitPanel',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/visitPanel')),
        meta: {
          title: '拜访统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/skuPanel',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/sku/skuPanel')),
        meta: {
          title: 'sku统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/dockingList',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/dockingList/dockingList')),
        meta: {
          title: '对接工单 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/conversionFunnel',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/conversionFunnel/conversionFunnel')),
        meta: {
          title: '漏斗统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/accountPanel',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/accountPanel')),
        meta: {
          title: '账号统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/smartParityPanel',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/smartParity')),
        meta: {
          title: '智能比价工具 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/supply',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/supplyPanel/supply')),
        meta: {
          title: '供应力统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/buySupply',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/supplyPanel/buySupply')),
        meta: {
          title: '采购力统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/setSupply',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/supplyPanel/setSupply')),
        meta: {
          title: '供应力指标设置 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/brandSupply',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/supplyPanel/brandSupply')),
        meta: {
          title: '品牌供应力 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/hotSku',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/sku/hotSku')),
        meta: {
          title: '热门sku | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/supplyDemand',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/supplyPanel/supplyDemand')),
        meta: {
          title: '供采分析 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/queryAnalysis',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/queryAnalysis')),
        meta: {
          title: '查询转化分析 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/memberFees',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/memberFees')),
        meta: {
          title: '会员收费漏斗 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/rankingList',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/rankingList')),
        meta: {
          title: '排行榜 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/productAnalysis',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/productAnalysis')),
        meta: {
          title: '产品分析 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/carTypePanel',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/sku/carTypePanel')),
        meta: {
          title: 'sku统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/dismantlingParts',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/dismantlingParts')),
        meta: {
          title: '拆车件面板 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/backstageApiManage',
        component: asyncComponent(() => import(/* webpackChunkName: 'operate' */ '@/pages/operate/child/backstageApiManage')),
        meta: {
          title: '后台接口权限 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/supplySku',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/supplyPanel/supplySku')),
        meta: {
          title: '供应商SKU变化图表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/platSopPanel',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/platSopPanel')),
        meta: {
          title: '平台概况 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/regionalPerformance',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/regionalPerformance')),
        meta: {
          title: '区域概况 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/coupon',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/coupon/statistics')),
        meta: {
          title: '优惠券统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/couponTransform',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/coupon/transform')),
        meta: {
          title: '优惠券转化统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/imStatistic',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/imStatistic')),
        meta: {
          title: 'IM会话统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/purchaseBrandOrder',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/purchaseBrandOrder')),
        meta: {
          title: '犇犇盟采购品牌订单 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/milepostAccount',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/milepost/milepostAccount')),
        meta: {
          title: '里程碑统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/milepostRecord',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/milepost/milepostRecord')),
        meta: {
          title: '里程碑记录 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/baseAnalysis',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/fullParts/baseAnalysis')),
        meta: {
          title: '满配报表1 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/fullAnalysis',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/fullParts/fullAnalysis')),
        meta: {
          title: '满配报表2 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/decodeList',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/xtrade/decodeList')),
        meta: {
          title: '定码单管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/inquiryList',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/xtrade/inquiryList')),
        meta: {
          title: '询价单管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/clientList',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/xtrade/clientList')),
        meta: {
          title: '客户列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/operate/offerMonitoring',
        component: asyncComponent(() => import(/* webpackChunkName: "operate" */'@/pages/operate/child/offerMonitoring')),
        meta: {
          title: '流程监控 | 犇犇汽配联盟后台'
        }
      }
    ]
  }
];

export default route;
