import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Icon, Tabs, message } from 'antd';
import styles from './style.module.less'
import { setStaffDetail } from '@/store/actions'
import { findStaffMenuList } from '@/api/userInfo'
import commonurl  from '@/axios/commonurl'
import { withAliveScope } from 'react-activation'
const { SubMenu } = Menu;
const { TabPane } = Tabs
@withAliveScope
class SideMenu extends Component {
  constructor (props) {
    super(props);
    this.state = {
      intoFirst: true,
      collapsed: false,
      menuList: [], // 菜单列表
      openKeys: [],
      selectedKeys: [],
      tabsList:[], // tabs列表
      activeKey:'0' // 当前激活tab面包的key
    }
  }
  // 生命周期
  componentDidMount () {
    this.getmenuList()
    this.props.setStaffDetail()
  }
  // 获取当前页面路由并初始化标签页
  getRoute = () => {
    const pathname = this.props.location.pathname
    let thisObj = null
    this.setState({
      pathname
    },()=>{
      const menuList = [...this.state.menuList]
      const tabsList = [...this.state.tabsList]
      let activeKey = this.state.activeKey
      menuList.forEach(e => {
        if(e.childList.length !== 0){
          e.childList.forEach(x => {
            if(x.url === this.state.pathname){
              tabsList.push(
                {
                  title:x.menuItem,
                  url:x.url,
                  key:x.menuId,
                  parentId:x.parentId,
                  urlType: x.urlType
                }
              )
              activeKey = x.menuId
              thisObj = x
            }
          })
        }
      })
      this.setState({
        tabsList,
        activeKey
      }, () => {
        let crumbsData = sessionStorage.getItem('crumbsData')
        if (crumbsData) {
          crumbsData = JSON.parse(crumbsData)
          this.setState({
            tabsList: crumbsData
          })
        }
        if (thisObj) {
          this.changeKey(thisObj.menuId,thisObj.url,thisObj.parentId)
        }
      })
    })
  }

  // 获取菜单列表
  getmenuList () {
    findStaffMenuList().then(res => {
      if (res.data.code === '200') {
        let data = res.data.data
        let location = this.props.location
        for (let i = 0; i < data.length; i++) {
          let returnBreak = false
          for (let j = 0; j <  data[i].childList.length; j++) {
            let item =  data[i].childList[j]
            item.urlType = item.url.indexOf('/system/') > - 1 ? 'old' : 'new'
            if (location && location.pathname === item.url) {
              returnBreak = true
              this.setState({
                openKeys: [data[i].menuId],
                selectedKeys: [item.url]
              })
              break
            }
          }
          if (returnBreak) break
        }
        this.setState({
          menuList: data
        },()=>{
          this.getRoute()
        })
      } else {
        message.error(res.data.msg)
        if (res.data.code === '2') {
          // 用户未登录
          setTimeout(() => {
            window.location.href = '/login'
            // window.location.replace(commonurl.bbMngPath + '/login/loginPage')
          }, 1500)
        }
      }
    })
  }

  // 动态变化标签页
  addTabs = (item) => {
    const tabsList = [...this.state.tabsList]
    let activeKey = item.menuId
    let isNew = tabsList.find(item => item.key === activeKey)
    item.urlType = item.url.indexOf('/system/') > - 1 ? 'old' : 'new'
    if(isNew === undefined){
      tabsList.push({
        title:item.menuItem,
        url:item.url,
        key:item.menuId,
        parentId:item.parentId,
        urlType: item.urlType
      })
      this.setState({
        tabsList,
        activeKey
      }, () => {
        // 刷新页面回显导航
        let crumbsData = JSON.stringify(this.state.tabsList)
        sessionStorage.setItem('crumbsData', crumbsData)
      })
    }
  }

  // 切换标签页后改变样式
  changeKey = (key,url,parentId) => {
    this.setState({
      activeKey:key,
      selectedKeys:[url],
      openKeys:[parentId]
    },()=>{
      let otherTab = document.getElementsByClassName('ant-tabs-tab');//包含了当前的active
      for (let j in otherTab){
        if (otherTab.hasOwnProperty(j)){
          let i = otherTab[j]
          if (i.className.includes('ant-tabs-tab-active')){//当前点击的
            i.style.backgroundColor = 'white';
          } else {//其他默认的样式
            i.style.backgroundColor = 'rgb(224,228,229)';
          }
        }
      }
    })
  }

  // 切换标签页
  changeTab = activeKey => {
    const tabsList = [...this.state.tabsList]
    tabsList.forEach(e => {
      if (activeKey === e.key){
        // this.props.history.push(e.url)
        this.changeKey(e.key,e.url,e.parentId)
      }
    })
  }

  // 删除标签的回调 
  onEdit = (targetKey, action) => {
    this[action](targetKey);
  }

  // 删除标签 
  remove = targetKey =>{
    const tabsList = [...this.state.tabsList]
    const { dropScope, clear } = this.props
    if (tabsList.length !== 1) {
      if(targetKey !== '0'){
        tabsList.forEach((e,index) => {
          if(e.key === targetKey){
            tabsList.splice(index,1)
            if (dropScope) {
              dropScope(e.url)
            }
          }
        })
      }
      this.setState({
        tabsList,
        activeKey:tabsList[tabsList.length-1].key,
        selectedKeys:[tabsList[tabsList.length-1].url],
        openKeys:[tabsList[tabsList.length-1].parentId]
      },()=>{
        this.changeKey(this.state.activeKey,tabsList[tabsList.length-1].url,tabsList[tabsList.length-1].parentId)
        this.props.history.push(tabsList[tabsList.length-1].url)
        let crumbsData = JSON.stringify(this.state.tabsList)
        sessionStorage.setItem('crumbsData', crumbsData)
      })
    } else {
      this.setState({
        tabsList: [],
        activeKey: '0',
        openKeys: [],
        selectedKeys: [],
      })
      this.props.history.push('/home')
      if (clear) {
        clear()
      }
      sessionStorage.removeItem('crumbsData');
    }
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  onOpenChange(openKeys){
    // const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    // console.log(openKeys, latestOpenKey)
    // if (latestOpenKey) {
    //   this.setState({
    //     openKeys: latestOpenKey ? [latestOpenKey] : []
    //   })
    // }
    if (openKeys.length === 1 || openKeys.length === 0) {
      this.setState({
        openKeys
      })
      return false
    }
    const latestOpenKey = openKeys[openKeys.length - 1]
    if (latestOpenKey.includes(openKeys[0])) {
      this.setState({
        openKeys
      })
    } else {
      this.setState({
        openKeys: [latestOpenKey]
      })
    }
  }

  onSelectChange(item){
    const latestSelectKey = this.state.selectedKeys.indexOf(item.key) === -1;
    if (latestSelectKey) {
      this.setState({
        selectedKeys: [item.key]
      })
      // this.props.history.push({
      //   pathname: item.key
      // })
    }
  }

  render() {
    const { menuList } = this.state
    return (
      <div>
        <div className={`side-menu ${styles['side-menu']}`}>
          <Menu
            mode="inline"
            theme="light"
            openKeys={this.state.openKeys}
            selectedKeys={this.state.selectedKeys}
            inlineCollapsed={this.state.collapsed}
            onOpenChange={this.onOpenChange.bind(this)}
            onSelect={this.onSelectChange.bind(this)}
          >
            {menuList.map((submenu) => (
              <SubMenu
                key={submenu.menuId}
                title={
                  <span>
                    <Icon type="setting" />
                    <span>{submenu.menuItem}</span>
                  </span>
                }
              >
                {submenu.childList.map(item => (
                  <Menu.Item key={item.url} onClick={() => this.addTabs(item)}>
                    <Link to={{pathname: item.url,state:{fromMenu:'1'}}} onClick={() => this.changeKey(item.menuId,item.url,item.parentId)}>{item.menuItem}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        </div>
        {
          this.state.tabsList.length > 0 &&
          <div className={`tabs ${styles['tabs']}`}>
            <Tabs
              activeKey={this.state.activeKey}
              type="editable-card"
              hideAdd
              tabBarGutter={5}
              onChange={this.changeTab}
              onEdit={this.onEdit}
            >
              {this.state.tabsList.map((pane, index) => (
                <TabPane 
                  tab={
                    <Link to={{pathname:pane.url, state:{fromMenu:'0'}}}
                      style={{color:this.state.activeKey === pane.key ? '#1890ff' : 'rgba(0, 0, 0, 0.65)'}}
                    >
                      {pane.title}
                    </Link>
                  }
                  key={pane.key}>
                  {
                    pane.urlType === 'old' && 
                    <div>
                      <iframe title={pane.title} id={'iframes' + index} scrolling="yes" frameBorder="0" className={`${styles['iframe-cont']}`}
                        src={commonurl.bbMngPath + pane.url}
                        onLoad={() => {
                          let dom = document.getElementById('iframes'+ index)
                          const deviceHeight = document.documentElement.clientHeight
                          dom.style.height = (Number(deviceHeight)) - 100 + 'px';
                        }}
                      />
                    </div>
                  }
                </TabPane>
              ))}
            </Tabs>
          </div>
        }
      </div>
    );
  }
}

// 需要渲染什么数据
function mapStateToProps (state) {
  return {
    staffDetail: state.staffDetail
  }
}
// 需要触发什么行为
function mapDispatchToProps (dispatch) {
  return {
    setStaffDetail,
  }
}

/*
connect：它是一个高阶组件 有四个参数([mapStateToProps], [mapDispatchToProps], [mergeProps], [options])，后面两个参数可以不写，不写的话它是有默认值的。
        我们主要关注下前两个参数mapStateToProps和mapDispatchToProps。
        connect的使用方法是：把指定的state和指定的action与React组件连接起来，后面括号里面写UI组件名。
*/
SideMenu = connect(mapStateToProps, mapDispatchToProps)(SideMenu)

export default withRouter(SideMenu)
