// action：行为 它是一个对象 里面必有type来指定其类型 这个类型可以理解为你要做什么
import store from './index'
import { SETSTAFFDETAIL } from './action-type'
import { getStaffDetail } from '@/api/userInfo'

function setUserInfo (userInfo) {
  store.dispatch({type: SETSTAFFDETAIL, data: userInfo})
}

// 获取登录员工信息
export const setStaffDetail = () => {
  getStaffDetail().then(res => {
    if (res.data.code === '200') {
      setUserInfo(res.data.data)
    }
  })
}
