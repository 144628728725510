import React from 'react'
import styles from './style.module.less'
import { Form, Icon, Input, Button, message } from 'antd';
import {
  login
} from '@/api/system'
class Login extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        login({
          account: values.account,
          password: values.password
        }).then(res => {
          if (res.data.code==='200' || res.data.code==='5'){
            message.success(res.data.msg)
            window.location.href = '/home'
          } else {
            message.error(res.data.msg)
          }
          this.setState({
            pullVisible: false
          })
        })
      }
    });
  };
  goHome = () => {
    window.location.href = '/home'
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={styles['login-box']}>
        <div className={styles['htmlheader']}>
          <div className={styles['header-wrap']} onClick={this.goHome}>
            <img className={styles['logo-img']} src={require('../../assets/images/login/logo_login.png')} alt="logo" />
            <span className={styles['welcome']}>汽配运营后台</span>
          </div>
        </div>
        <div className={styles['login-cont']} >
          <div className={styles['login-flex']}>
            <div className={styles['login-item']}>
              <img className={styles['login-banner']} src={require('../../assets/images/login/login-banner.jpg')} alt="login" />
              <div className={styles['login-right']}>
                <div className={styles['login-title']}>
                  账号登录
                </div>
                <div className={styles['prompt']}>
                  <div className={styles['pro1']}>
                    <img src={require('../../assets/images/login/tips.jpg')} alt="tips"/> 
                    <span className={styles['error-msg']}>
                      公共场所不建议浏览器记住账号，以防账号丢失
                    </span>
                  </div>
                  <div style={{marginTop: 20}}>
                    <Form onSubmit={this.handleSubmit} className="login-form">
                      <Form.Item>
                        {getFieldDecorator('account', {
                          rules: [{ required: true, message: '请输入用户名/已绑定手机！' }],
                        })(
                          <Input
                            className={styles['login-Input']}
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)', }} />}
                            placeholder="用户名/已绑定手机"
                          />,
                        )}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator('password', {
                          rules: [{ required: true, message: '请输入密码！' }],
                        })(
                          <Input
                            className={styles['login-Input']}
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="密码"
                          />,
                        )}
                      </Form.Item>
                      <Form.Item>
                        <Button type="danger" size={'large'} block htmlType="submit" className="login-form-button">
                          登录
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['htmlfooter']}>
            <div>
                <span>Copyright © 2017广州犇犇汽配信息技术有限公司  </span>
            </div>
            <div>
              <a href="https://beian.miit.gov.cn/" target={"_blank"}>粤ICP备17153757号</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Form.create({})(Login)