import request from '@/axios/request';

// 查询操作日志
export function findOperateLog(query) {
  return request({
    url: '/sys/findOperateLog',
    method: 'post',
    data: query
  });
}
// 员工登录
export function login(query) {
  return request({
    url: '/login/login',
    method: 'post',
    data: query
  });
}
// 员工登出
export function loginOut(query) {
  return request({
    url: '/login/loginOut',
    method: 'post',
    data: query
  });
}
// 供应商加分设置
export function findStrictSetting(query) {
  return request({
    url: '/auth/strictSetting/find',
    method: 'post',
    data: query
  });
}
// 修改供应商加分设置
export function updateStrictSetting(query) {
  return request({
    url: '/auth/strictSetting/updateBatch',
    method: 'post',
    data: query
  });
}
// 根据id删除供应商加分配置项
export function deleteById(query) {
  return request({
    url: '/auth/strictSetting/deleteById',
    method: 'post',
    data: query
  });
}
