import asyncComponent from '@/components/asyncComponent/asyncComponent';

import Home from '@/pages/home';
import Login from '@/pages/login/login';
import operateRoute from './operateRoute'; // 运营面板
import promotionRoute from './promotionRoute'; // 促销管理
import leagueRouter from './leagueRouter'; // 促销管理

const routeConfig = [{
    path: '/home',
    component: Home
  },
  {
    path: '/login',
    component: Login,
  },
  ...operateRoute, // 运营面板
  ...promotionRoute, // 促销管理
  ...leagueRouter, //联盟专区路由
  {
    path: '/auth/data',
    component: asyncComponent(() => import( /* webpackChunkName: "data" */ '@/pages/data')),
    routes: [{
        path: '/auth/data/inviteRegister',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */
          '@/pages/data/child/inviteRegister')),
        meta: {
          title: '邀请注册统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/data/brandParts',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */ '@/pages/data/child/brandParts')),
        meta: {
          title: '品牌映射工单 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/data/carPartsManufacturer',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */
          '@/pages/data/child/carPartsManufacturer')),
        meta: {
          title: '零件品牌维护 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/data/carPartsSwap',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */ '@/pages/data/child/carPartsSwap')),
        meta: {
          title: '平台互换码管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/data/ksStandardCarParts',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */
          '@/pages/data/child/ksStandardCarParts')),
        meta: {
          title: '零件标准数据管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/data/stockHolderInvite',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */
          '@/pages/data/child/stockHolderInvite')),
        meta: {
          title: '股东邀请注册统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/data/weixinUser',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */ '@/pages/data/child/weixinUser')),
        meta: {
          title: '微信用户 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/data/errorCorrect',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */ '@/pages/data/child/errorCorrect')),
        meta: {
          title: '纠错管理 | 犇犇汽配联盟后台',
        }
      },
      {
        path: '/auth/data/pushList',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */ '@/pages/data/child/pushList')),
        meta: {
          title: '犇犇盟供应商推送管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/data/carTypeManage',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */ '@/pages/data/child/carTypeManage')),
        meta: {
          title: '车型管理 | 犇犇汽配联盟后台',
        }
      },
      {
        path: '/auth/data/addCarType',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */ '@/pages/data/child/addCarType')),
        meta: {
          title: '车型管理 | 犇犇汽配联盟后台',
        }
      },
      {
        path: '/auth/data/addBrand',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */ '@/pages/data/child/addBrand')),
        meta: {
          title: '新增品牌 | 犇犇汽配联盟后台',
        }
      },
      {
        path: '/auth/data/inspection',
        component: asyncComponent(() => import( /* webpackChunkName: 'data' */
          '@/pages/data/child/inspectionPage')),
        meta: {
          title: '验货管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/data/brandOrderSort',
        component: asyncComponent(() => import( /* webpackChunkName: 'data' */
          '@/pages/data/child/brandOrderSort')),
        meta: {
          title: '品牌订单排序 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/data/delSupplierPart',
        component: asyncComponent(() => import( /* webpackChunkName: 'data' */
          '@/pages/data/child/delSupplierPart'))
      },
      {
        path: '/auth/data/empowerList',
        component: asyncComponent(() => import( /* webpackChunkName: "data" */ '@/pages/data/empower/index')),
        meta: {
          title: '品牌授权管理 | 犇犇汽配联盟后台'
        }
      },
    ]
  },
  { // 标签管理
    path: '/auth/label',
    component: asyncComponent(() => import( /* webpackChunkName: "label" */ '@/pages/label')),
    routes: [{
        path: '/auth/label/ignorePage',
        component: asyncComponent(() => import( /* webpackChunkName: "label" */ '@/pages/label/child/ignorePage')),
        meta: {
          title: '忽略标签列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/label/list',
        component: asyncComponent(() => import( /* webpackChunkName: "label" */ '@/pages/label/child/qyLabelList')),
        meta: {
          title: '企业标签列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/label/index',
        component: asyncComponent(() => import( /* webpackChunkName: "label" */ '@/pages/label/child/labelList')),
        meta: {
          title: '标签列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/label/remarkAudit',
        component: asyncComponent(() => import( /* webpackChunkName:"label" */ '@/pages/label/child/remarkAudit')),
        meta: {
          title: '备注审核 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/label/carPartsError',
        component: asyncComponent(() => import( /* webpackChunkName:"label" */
          '@/pages/label/child/carPartsError')),
        meta: {
          title: '可疑件问题件标签 | 犇犇汽配联盟后台'
        },
      },
      {
        path: '/auth/label/errorLabel',
        component: asyncComponent(() => import( /* webpackChunkName:"label" */ '@/pages/label/child/errorLabel')),
        meta: {
          title: '可疑件问题件-分析问题件过滤 | 犇犇汽配联盟后台'
        },
      }
    ]
  },
  { // IM管理
    path: '/auth/im',
    component: asyncComponent(() => import( /* webpackChunkName: "IM" */ '@/pages/IM')),
    routes: [{
        path: '/auth/im/ImItemsPage',
        component: asyncComponent(() => import( /* webpackChunkName: "IM" */ '@/pages/IM/child/imItemsPage')),
        meta: {
          title: 'IM词条管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/im/imBrandsPage',
        component: asyncComponent(() => import( /* webpackChunkName: "IM" */ '@/pages/IM/child/imBrandsPage')),
        meta: {
          title: 'IM推荐品牌 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/im/addBrandsPage',
        component: asyncComponent(() => import( /* webpackChunkName: "IM" */ '@/pages/IM/child/addBrandsPage')),
        meta: {
          title: '新建品牌 | 犇犇汽配联盟后台'
        }
      },
    ]
  },
  {
    path: '/auth/afterSale',
    component: asyncComponent(() => import( /* webpackChunkName: "afterSale" */ '@/pages/afterSale')),
    routes: [{
      path: '/auth/afterSale/feedback',
      component: asyncComponent(() => import( /* webpackChunkName: "afterSale" */
        '@/pages/afterSale/child/feedback')),
      meta: {
        title: '用户意见反馈 | 犇犇汽配联盟后台'
      }
    }, ]
  },
  {
    // 文章管理
    path: '/auth/article',
    component: asyncComponent(() => import( /* webpackChunkName: "article" */ '@/pages/article/index')),
    routes: [{
        path: '/auth/article/list',
        component: asyncComponent(() => import( /* webpackChunkName: "article" */
          '@/pages/article/child/articleListPage')),
        meta: {
          title: '公告列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/article/add',
        component: asyncComponent(() => import( /* webpackChunkName: "article" */
          '@/pages/article/child/articleAddPage')),
        meta: {
          title: '新建公告 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/article/groupList',
        component: asyncComponent(() => import( /* webpackChunkName: "article" */
          '@/pages/article/child/groupListPage')),
        meta: {
          title: '群发消息列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/article/groupAdd',
        component: asyncComponent(() => import( /* webpackChunkName: "article" */
          '@/pages/article/child/groupAddPage')),
        meta: {
          title: '新建群发消息 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/article/advertList',
        component: asyncComponent(() => import( /* webpackChunkName: "article" */
          '@/pages/article/child/advertListPage')),
        meta: {
          title: '广告列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/article/addAdvert',
        component: asyncComponent(() => import( /* webpackChunkName: "article" */
          '@/pages/article/child/addAdvertPage')),
        meta: {
          title: '广告添加 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/article/helpCenterManage',
        component: asyncComponent(() => import( /* webpackChunkName: "article" */
          '@/pages/article/child/helpCenterManage')),
        meta: {
          title: '帮助中心管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/article/helpCenterClassfic',
        component: asyncComponent(() => import( /* webpackChunkName: "article" */
          '@/pages/article/child/helpCenterClassfic')),
        meta: {
          title: '帮助中心分类管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/article/addHelpCenterPage',
        component: asyncComponent(() => import( /* webpackChunkName: "article" */
          '@/pages/article/child/addHelpCenterPage')),
        meta: {
          title: '新增帮助中心 | 犇犇汽配联盟后台'
        }
      }
    ]
  },
  { // 企业等级
    path: '/auth/level',
    component: asyncComponent(() => import( /* webpackChunkName: "level" */ '@/pages/level')),
    routes: [{
        path: '/auth/level/goldList',
        component: asyncComponent(() => import( /* webpackChunkName: "level" */ '@/pages/level/child/goldList')),
        meta: {
          title: '诚意金列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/level/levelList',
        component: asyncComponent(() => import( /* webpackChunkName: "level" */ '@/pages/level/child/levelList')),
        meta: {
          title: '企业等级列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/level/queryList',
        component: asyncComponent(() => import( /* webpackChunkName: "level" */ '@/pages/level/child/queryList')),
        meta: {
          title: '按次充值列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/level/rechargeList',
        component: asyncComponent(() => import( /* webpackChunkName: "level" */
          '@/pages/level/child/rechargeList')),
        meta: {
          title: '充值列表 | 犇犇汽配联盟后台'
        }
      },
    ]
  },
  { // 保证金管理
    path: '/auth/margin',
    component: asyncComponent(() => import( /* webpackChunkName: "margin" */ '@/pages/margin')),
    routes: [{
        path: '/auth/margin/accountsList',
        component: asyncComponent(() => import( /* webpackChunkName: "margin" */
          '@/pages/margin/child/accountsList')),
        meta: {
          title: '供应商挂账申请 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/margin/platformList',
        component: asyncComponent(() => import( /* webpackChunkName: "margin" */
          '@/pages/margin/child/platformList')),
        meta: {
          title: '平台挂账申请 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/margin/creditList',
        component: asyncComponent(() => import( /* webpackChunkName: "margin" */
          '@/pages/margin/child/creditList')),
        meta: {
          title: '供应商挂账记录 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/margin/platformCreditList',
        component: asyncComponent(() => import( /* webpackChunkName: "margin" */
          '@/pages/margin/child/platformCreditList')),
        meta: {
          title: '平台挂账记录 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/margin/settlementList',
        component: asyncComponent(() => import( /* webpackChunkName: "margin" */
          '@/pages/margin/child/settlementList')),
        meta: {
          title: '平台结算记录 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/margin/queryList',
        component: asyncComponent(() => import( /* webpackChunkName: "margin" */ '@/pages/margin/child/queryList')),
        meta: {
          title: '保证金列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/margin/supplierPlatformCredit',
        component: asyncComponent(() => import( /* webpackChunkName: "margin" */
          '@/pages/margin/child/supplierPlatformCredit')),
        meta: {
          title: '供应商支持平台挂账 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/margin/supplierUnpaidCredit',
        component: asyncComponent(() => import( /* webpackChunkName: "margin" */
          '@/pages/margin/child/supplierUnpaidCredit')),
        meta: {
          title: '卖家未保证金管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/margin/supplierPaidCredit',
        component: asyncComponent(() => import( /* webpackChunkName: "margin" */
          '@/pages/margin/child/supplierPaidCredit')),
        meta: {
          title: '卖家保证金管理 | 犇犇汽配联盟后台'
        }
      }
    ]
  },
  { // 会员活动
    path: '/auth/memberActivity',
    component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */ '@/pages/memberActivity')),
    routes: [{
        path: '/auth/memberActivity/activityList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/kpiList')),
        meta: {
          title: '会员活动KPI列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/add',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/add')),
        meta: {
          title: '创建活动 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/statisticsList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/statisticsList')),
        meta: {
          title: '会员活动统计列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/enrollList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/enrollList')),
        meta: {
          title: '报名活动列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/setActivity',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/setActivity')),
        meta: {
          title: '设置会员活动 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/taskList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/taskList')),
        meta: {
          title: '新手任务列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/addTask',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/addTask')),
        meta: {
          title: '创建任务 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/promotionPlan',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/promotionPlan/index')),
        meta: {
          title: '推广计划 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/editPromotionPlan',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/promotionPlan/edit')),
        meta: {
          title: '编辑推广计划 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/detailPromotionPlan',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/promotionPlan/detail')),
        meta: {
          title: '计划详情 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/lotteryList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/lottery/index')),
        meta: {
          title: '抽奖活动 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/editLottery',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/lottery/edit')),
        meta: {
          title: '新增/修改抽奖活动 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/setPrize',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/lottery/setPrize')),
        meta: {
          title: '设置奖品 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/gradeList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/gradeList')),
        meta: {
          title: '商家评级列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/countPartNo',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/countPartNo')),
        meta: {
          title: '编码查询数据统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberActivity/addActivity',
        component: asyncComponent(() => import( /* webpackChunkName: "memberActivity" */
          '@/pages/memberActivity/child/addActivity')),
        meta: {
          title: '创建活动 | 犇犇汽配联盟后台'
        }
      }
    ]
  },
  { // 会员资料管理
    path: '/auth/memberUser',
    component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */ '@/pages/memberInfo/index')),
    routes: [{
        path: '/auth/memberUser/erpApplyList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/erpApplyList')),
        meta: {
          title: 'erp申请审核列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/platformApplyList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/platformApplyList')),
        meta: {
          title: '犇犇开放平台申请列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/shopApplyList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/shopApplyList')),
        meta: {
          title: '旗舰店审核列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/applyList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/applyList')),
        meta: {
          title: '对接业务申请列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/vipList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/vipList')),
        meta: {
          title: '工单管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/bsPage',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/bsPage')),
        meta: {
          title: '申请卖家审核 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/businessDetail',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/businessDetail')),
        meta: {
          title: '卖家资料 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/purchaserPage',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/purchaserPage')),
        meta: {
          title: '企业资料审核 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/purchaserDetail',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/purchaserDetail')),
        meta: {
          title: '企业资料信息 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/followSeller',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/followSeller')),
        meta: {
          title: '卖家跟进状态 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/staticBussinessWarranty',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/staticBussinessWarranty')),
        meta: {
          title: '严选商家质保 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/companyMessage',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/companyMessage')),
        meta: {
          title: '企业信息维护 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/editBasic',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/companyMessage/editBasic')),
        meta: {
          title: '修改企业信息 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/purchaserDetailSkan',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/companyMessage/purchaserDetailSkan')),
        meta: {
          title: '企业资料 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/userStoreLog',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/userStoreLog')),
        meta: {
          title: '用户积分操作日志 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/companyStoreLog',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/companyStoreLog')),
        meta: {
          title: '企业积分操作日志 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/companyGrowthLog',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/companyGrowthLog')),
        meta: {
          title: '企业成长值操作日志 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/experienceList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/experiencePage/list')),
        meta: {
          title: '体验账号信息维护 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/comqqApplyPage',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/comqqApplyPage')),
        meta: {
          title: '企Q申请审核 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/qqCompanyEdit',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/qqCompanyPage/editBasic')),
        meta: {
          title: '查看企Q申请信息 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/fullSendSetting',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/companyMessage/fullSendSetting')),
        meta: {
          title: '满配报价设置 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/aliasOrder',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/aliasOrder')),
        meta: {
          title: '别名库工单 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/memberExpiration',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/memberExpiration')),
        meta: {
          title: '会员到期统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/accountNumber',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/accountNumber')),
        meta: {
          title: '账号信息维护 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/memberUser/buyerList',
        component: asyncComponent(() => import( /* webpackChunkName: "memberUser" */
          '@/pages/memberInfo/child/buyerList')),
        meta: {
          title: '买家信息维护 | 犇犇汽配联盟后台'
        }
      }
    ]
  },
  {
    path: '/auth/order',
    component: asyncComponent(() => import( /* webpackChunkName: "order" */ '@/pages/order/index')),
    routes: [{
        path: '/auth/order/orderInfoList',
        component: asyncComponent(() => import( /* webpackChunkName: "order" */
          '@/pages/order/child/orderInfoList')),
        meta: {
          title: '订单列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/order/orderDetail',
        component: asyncComponent(() => import( /* webpackChunkName: "order" */ '@/pages/order/child/orderDetail')),
        meta: {
          title: '订单详情 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/order/logisticsManagement',
        component: asyncComponent(() => import( /* webpackChunkName: "order" */
          '@/pages/order/child/logisticsManagement/logisticsManagement')),
        meta: {
          title: '物流管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/order/askRequistPriceList',
        component: asyncComponent(() => import( /* webpackChunkName: "order" */
          '@/pages/order/child/askRequistPriceList')),
        meta: {
          title: '采询价单列表 | 犇犇汽配联盟后台'
        }
      }
    ]
  },
  {
    path: '/auth/refund',
    component: asyncComponent(() => import( /* webpackChunkName: "refund" */ '@/pages/refund/index')),
    routes: [{
        path: '/auth/refund/refundPage',
        component: asyncComponent(() => import( /* webpackChunkName: "refund" */
          '@/pages/refund/refundPage/refundPage')),
        meta: {
          title: '售后订单列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/refund/refundDetailPage/:refundNo',
        component: asyncComponent(() => import( /* webpackChunkName: "refund" */
          '@/pages/refund/refundDetailPage/index')),
        meta: {
          title: '售后订单详情 | 犇犇汽配联盟后台'
        }
      }
    ]
  },
  {
    path: '/auth/shop',
    component: asyncComponent(() => import( /* webpackChunkName: "shop" */ '@/pages/shop/index')),
    routes: [{
        path: '/auth/shop/shopPage',
        component: asyncComponent(() => import( /* webpackChunkName: "shop" */ '@/pages/shop/child/shopPage')),
        meta: {
          title: '推荐店铺 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/shop/flagshipShopPage',
        component: asyncComponent(() => import( /* webpackChunkName: "shop" */
          '@/pages/shop/child/flagshipShopPage')),
        meta: {
          title: '旗舰店列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/shop/setBlackList',
        component: asyncComponent(() => import( /* webpackChunkName: "shop" */ '@/pages/shop/child/setBlackList')),
        meta: {
          title: '查价黑名单功能配置 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/shop/shopManage',
        component: asyncComponent(() => import( /* webpackChunkName: "shop" */ '@/pages/shop/child/shopManage')),
        meta: {
          title: '店铺管理 | 犇犇汽配联盟后台'
        }
      },
    ]
  },
  {
    path: '/auth/live',
    component: asyncComponent(() => import( /* webpackChunkName: "live" */ '@/pages/live/index')),
    routes: [{
        path: '/auth/live/liveListPage',
        component: asyncComponent(() => import( /* webpackChunkName: "live" */ '@/pages/live/child/liveListPage')),
        meta: {
          title: '直播间列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/live/columnPage',
        component: asyncComponent(() => import( /* webpackChunkName: "live" */ '@/pages/live/child/columnPage')),
        meta: {
          title: '直播间栏目设置 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/live/skanRewardRecord',
        component: asyncComponent(() => import( /* webpackChunkName: "live" */
          '@/pages/live/child/skanRewardRecord')),
        meta: {
          title: '设置和查看 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/live/liveUploadGoods',
        component: asyncComponent(() => import( /* webpackChunkName: "live" */
          '@/pages/live/child/liveUploadGoods')),
        meta: {
          title: '添加商品 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/live/publicSetting',
        component: asyncComponent(() => import( /* webpackChunkName: "live" */ '@/pages/live/child/publicSetting')),
        meta: {
          title: '公共设置 | 犇犇汽配联盟后台'
        }
      },
    ]
  },
  {
    path: '/auth/integral',
    component: asyncComponent(() => import( /* webpackChunkName: "integral" */ '@/pages/integral/index')),
    routes: [{
        path: '/auth/integral/integralListPage',
        component: asyncComponent(() => import( /* webpackChunkName: "integral" */
          '@/pages/integral/child/integralList')),
        meta: {
          title: '兑换商品管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/integral/editIntegralPage',
        component: asyncComponent(() => import( /* webpackChunkName: "integral" */
          '@/pages/integral/child/editIntegral')),
        meta: {
          title: '新增/编辑兑换商品 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/integral/companyCoverRecord',
        component: asyncComponent(() => import( /* webpackChunkName: "integral" */
          '@/pages/integral/child/companyCoverRecord')),
        meta: {
          title: '新企业兑换记录 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/integral/userCoverRecord',
        component: asyncComponent(() => import( /* webpackChunkName: "integral" */
          '@/pages/integral/child/userCoverRecord')),
        meta: {
          title: '新用户兑换记录 | 犇犇汽配联盟后台'
        }
      },
    ]
  },
  {
    path: '/auth/openPlatform',
    component: asyncComponent(() => import( /* webpackChunkName: 'openPlatform' */ '@/pages/integral/index')),
    routes: [{
        path: '/auth/openPlatform/dockingManage',
        component: asyncComponent(() => import( /* webpackChunkName: 'openPlatform' */
          '@/pages/openPlatform/child/dockingManage')),
        meta: {
          title: '开放平台 对接管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/openPlatform/purviewSetting',
        component: asyncComponent(() => import( /* webpackChunkName: 'openPlatform' */
          '@/pages/openPlatform/child/purviewSetting')),
        meta: {
          title: '开放平台 对接管理 | 犇犇汽配联盟后台'
        }
      }
    ]
  },
  {
    path: '/auth/supplier',
    component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */ '@/pages/supplier/index')),
    routes: [{
        path: '/auth/supplier/dockManage',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/child/dockManage')),
        meta: {
          title: '对接管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/supplier/editDockSupplier',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/child/editDockSupplier')),
        meta: {
          title: '对接管理编辑 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/supplier/partsMonitor',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/child/partsMonitor')),
        meta: {
          title: '零件监控 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/supplier/esList',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/child/esList')),
        meta: {
          title: 'ES零件列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/supplier/partsMarkList',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/partsMark/list')),
        meta: {
          title: '供应商配件标记 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/supplier/skanMark',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/partsMark/skanMark')),
        meta: {
          title: '查看标记 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/supplier/editMark',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/partsMark/editMark')),
        meta: {
          title: '新增/修改标记 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/supplier/partsUpLoad',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/child/partsUpLoad')),
        meta: {
          title: '供应商零件上传信息 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/supplier/stockUpErrLog',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/child/stockUpErrLog')),
        meta: {
          title: '库存上传错误日记 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/supplier/erpUpLog',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/child/erpUpLog')),
        meta: {
          title: 'ERP数据上传日志 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/supplier/offShelfAudit',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/child/offShelfAudit')),
        meta: {
          title: '原厂下架审核 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/supplier/SOLRList',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/child/SOLRList')),
        meta: {
          title: 'SOLR零件列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/supplier/benbenData',
        component: asyncComponent(() => import( /* webpackChunkName: 'supplier' */
          '@/pages/supplier/child/benbenData')),
        meta: {
          title: '犇犇盟数据查询 | 犇犇汽配联盟后台'
        }
      }
      // {
      //  path: '/auth/supplier/supplierCarPartsList',
      //  component: asyncComponent(() => import(/* webpackChunkName: 'supplier' */ '@/pages/supplier/child/supplierCarPartsList')),
      //  meta: {
      //    title: '库存信息 | 犇犇汽配联盟后台'
      //  }
      //}
    ]
  },
  {
    path: '/auth/achievements',
    component: asyncComponent(() => import( /* webpackChunkName: 'achievements' */ '@/pages/achievements/index')),
    routes: [{
        path: '/auth/achievements/bossCheck',
        component: asyncComponent(() => import( /* webpackChunkName: 'achievements' */
          '@/pages/achievements/child/bossCheck')),
        meta: {
          title: '绩效考核 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/achievements/typeOfAssessment',
        component: asyncComponent(() => import( /* webpackChunkName: 'achievements' */
          '@/pages/achievements/child/typeOfAssessment')),
        meta: {
          title: '考核种类 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/achievements/performanceCycle',
        component: asyncComponent(() => import( /* webpackChunkName: 'achievements' */
          '@/pages/achievements/child/performanceCycle')),
        meta: {
          title: '绩效周期 | 犇犇汽配联盟后台'
        }
      }
    ]
  },
  {
    path: '/auth/advert',
    component: asyncComponent(() => import( /* webpackChunkName: 'advert' */ '@/pages/advert/index')),
    routes: [{
        path: '/auth/advert/statistics',
        component: asyncComponent(() => import( /* webpackChunkName: 'advert' */ '@/pages/advert/statistics')),
        meta: {
          title: '广告统计 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/advert/tollModel',
        component: asyncComponent(() => import( /* webpackChunkName: 'advert' */ '@/pages/advert/tollModel')),
        meta: {
          title: '广告收费模式 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/advert/addModel',
        component: asyncComponent(() => import( /* webpackChunkName: 'advert' */ '@/pages/advert/addModel')),
        meta: {
          title: '新建/编辑收费模式 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/advert/advertOrderList',
        component: asyncComponent(() => import( /* webpackChunkName: 'advert' */ '@/pages/advert/advertOrderList')),
        meta: {
          title: '广告工单管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/advert/advertSchedule',
        component: asyncComponent(() => import( /* webpackChunkName: 'advert' */ '@/pages/advert/advertSchedule')),
        meta: {
          title: '广告排期 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/advert/creatAdvert',
        component: asyncComponent(() => import( /* webpackChunkName: 'advert' */ '@/pages/advert/creatAdvert')),
        meta: {
          title: '新建广告 | 犇犇汽配联盟后台'
        }
      }
    ]
  },
  {
    path: '/auth/erp',
    component: asyncComponent(() => import( /* webpackChunkName: 'erp' */ '@/pages/erp/index')),
    routes: [{
        path: '/auth/erp/errorList',
        component: asyncComponent(() => import( /* webpackChunkName: 'erp' */ '@/pages/erp/child/errorList')),
        meta: {
          title: 'ERP对接错误列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/erp/erpList',
        component: asyncComponent(() => import( /* webpackChunkName: 'erp' */ '@/pages/erp/child/erpList')),
        meta: {
          title: 'ERP列表 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/erp/erpProviderList',
        component: asyncComponent(() => import( /* webpackChunkName: 'erp' */ '@/pages/erp/child/erpProviderList')),
        meta: {
          title: 'ERP供应商列表 | 犇犇汽配联盟后台'
        }
      }
    ]
  },
  // 系统管理
  {
    path: '/auth/systemManagement',
    component: asyncComponent(() => import( /* webpackChunkName: 'systemManagement' */
      '@/pages/systemManagement/index')),
    routes: [{
        path: '/auth/systemManagement/sysLog',
        component: asyncComponent(() => import( /* webpackChunkName: 'systemManagement' */
          '@/pages/systemManagement/child/sysLog')),
        meta: {
          title: '操作日志 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/systemManagement/dataDictionary',
        component: asyncComponent(() => import( /* webpackChunkName: 'systemManagement' */
          '@/pages/systemManagement/child/dataDictionary')),
        meta: {
          title: '数据字典管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/systemManagement/changePwd',
        component: asyncComponent(() => import( /* webpackChunkName: 'systemManagement' */
          '@/pages/systemManagement/child/changePwd')),
        meta: {
          title: '修改密码 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/systemManagement/menuMGT',
        component: asyncComponent(() => import( /* webpackChunkName: 'systemManagement' */
          '@/pages/systemManagement/child/menuMGT')),
        meta: {
          title: '菜单管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/systemManagement/sysParamMGT',
        component: asyncComponent(() => import( /* webpackChunkName: 'systemManagement' */
          '@/pages/systemManagement/child/sysParamMGT')),
        meta: {
          title: '系统参数管理 | 犇犇汽配联盟后台'
        }
      },
      {
        path: '/auth/systemManagement/strictSetting',
        component: asyncComponent(() => import( /* webpackChunkName: 'systemManagement' */
          '@/pages/systemManagement/child/strictSetting')),
        meta: {
          title: '严选商家加分设置 | 犇犇汽配联盟后台'
        }
      }
    ]
  }
];

export default routeConfig;
