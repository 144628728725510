const REACE_ENV = process.env.REACT_APP_ENV;

const baseUrlMaps = {
  // 本地
  'development': {
    dubboCrmBaseURL: '/benben-dubbo-crm',
    bbMngBaseURL: '/mngPath',
    bbMngPath: 'https://sit.apbenben.com/league-mng',
    benbenPath: 'https://web-sit.apbenben.com',
    erpPath: 'https://test.erp.apware.cn',
  },
  // 测试
  'testing': {
    dubboCrmBaseURL: 'https://tapi.apbenben.com/benben-dubbo-crm',
    bbMngBaseURL: 'https://sit.apbenben.com/league-mng',
    bbMngPath: 'https://sit.apbenben.com/league-mng',
    benbenPath: 'https://web-sit.apbenben.com',
    erpPath: 'https://test.erp.apware.cn',
  },
  // 预发布
  'pre': {
    dubboCrmBaseURL: 'https://papi.apbenben.com/benben-dubbo-crm',
    bbMngBaseURL: 'https://pre.apbenben.com/league-mng',
    bbMngPath: 'https://pre.apbenben.com/league-mng',
    benbenPath: 'https://pre.apbenben.com',
    erpPath: 'https://benben.erp.apware.cn',
  },
  // 正式
  'production': {
    dubboCrmBaseURL: 'https://api.apbenben.com/benben-dubbo-crm',
    bbMngBaseURL: 'https://www.apbenben.com/league-mng',
    bbMngPath: 'https://www.apbenben.com/league-mng',
    benbenPath: 'https://web.apbenben.com',
    erpPath: 'https://benben.erp.apware.cn',
  }
};

export default baseUrlMaps[REACE_ENV];
