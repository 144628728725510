
import axios from 'axios'
import commonurl from './commonurl'
import { message } from 'antd'

// 创建axios实例
const service = axios.create({
  timeout: 300000 // 请求超时时间
})
// 设置全局的请求次数，请求的间隙
service.defaults.retry = 3
service.defaults.retryDelay = 1000

service.defaults.baseURL = '/benben-dubbo-crm'
service.defaults.withCredentials = true // 是否携带cookie信息
// service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// service.defaults.transformRequest = [function (data) { // form data请求
//   let ret = ''
//   for (let it in data) {
//     ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
//   }
//   return ret
// }]

function resultBaseURL (baseURL) {
  const urlMap = {
    '/benben-dubbo-crm': commonurl.dubboCrmBaseURL,
    '/mngPath': commonurl.bbMngBaseURL
    // '/benbenPath': commonurl.benbenpath,
    // '/weixinPath': commonurl.weixinpath,
  }
  return urlMap[baseURL] ? urlMap[baseURL] : baseURL
}
// request 请求拦截器
service.interceptors.request.use(config => {
  config.baseURL = resultBaseURL(config.baseURL)
  return config
})
// response 响应拦截器
service.interceptors.response.use(response => {
  // let resCode = response.data.code
  return response
}, error => {
  message.error('网络错误！');
  return Promise.reject(error)
})

/**
 *  @params {Object} config axios请求配置
 */
function request (config) {
//   let data = config.data // 请求参数
//   if (data) {
//     data = qs.stringify(config.data) // 序列化为formData参数
//     config.data = data
//   }
  return service(config)
}

export default request
