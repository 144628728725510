import request from '@/axios/request'

// 获取员工信息
export function getStaffDetail () {
    return request({
        url: '/staff/getStaffDetail',
        method: 'POST'
    })
}

// 获取员工系统菜单列表
export function findStaffMenuList (query) {
  return request({
    url: '/menu/findStaffMenuList',
    method: 'post',
    data: query
  })
}
