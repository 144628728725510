import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
// import ControlBoard from '@/components/controlBoard'
import PageFrame from '@/components/pageFrame';
import routeConfig from './routes';
import renderRoutes from './renderRoutes';
// import { AliveScope } from '@/components/keepAlive/keepAlive'
import { AliveScope } from 'react-activation';
/* import commonurl from '@/axios/commonurl'; */
// const isDev = process.env.REACT_APP_ENV === 'development' || 'testing';
/* var url='';
try { 
  url = window.top.document.referrer ; 
} catch(M) { 
  if (window.parent) { 
    try { 
      url = window.parent.document.referrer;
    } catch(L) { 
      url = '';
    } 
  } 
} 
if (url === '') { 
  url = document.referrer;
}
const isDev = (url + 'league-mng') !== commonurl.bbMngPath; */
const isLoginPage = window.location.pathname.indexOf('/login') > -1;
const isDev = window.location.pathname.indexOf('/login') === -1;
export class AppRoute extends Component {
  render () {
    return (
      <BrowserRouter>
        <div className="App">
          <Route path="/" render={props => {
            if (!isDev) {
              return null;
            }
            // 控制台组件
            return <PageFrame />;
          }}/>
          <AliveScope>
            <div id="fixedBox" className={isDev ? 'fixed-frame' : ''} style={{marginTop: !isLoginPage ? '40px' : ''}}>
              <Route path="/" exact render={props => {
                // 根路径默认跳转
                return <Redirect to="/auth/memberUser/companyMessage" />;
              }}/>
              {renderRoutes(routeConfig)}
            </div>
          </AliveScope>
        </div>
      </BrowserRouter>
    );
  }
}
