import React from 'react'
import { Provider } from 'react-redux'
import store from '@/store'
import { AppRoute } from './router'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'// 默认语言为 en-US，如果你需要设置其他语言，推荐在入口文件全局设置 locale
import 'moment/locale/zh-cn'
function App () {
  return (
    // 使用Provider 组件将APP主组件包裹住，这样内部组件都有Store种提供的属性。
    <Provider store={store}>
      <ConfigProvider locale={ zhCN }>
          <AppRoute></AppRoute>
      </ConfigProvider>
    </Provider>
  )
}

export default App
